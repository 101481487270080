const SA039Mx2SA906b0 = [
  { "s-node-SA039Mx2SA906b1": [371.47, 374.14] },
  { "s-node-SA039Mx2SA906b0": [184.71, 372.13] },
  { "s-node-SA039Mx2SA906b5": [561.34, 374] }
];
const SA532X1XB00118 = [
  { "s-node-SA532X9XB02755": [653.17, 380.25] },
  { "s-node-SA532X8XB01398": [569.51, 379.91] },
  { "s-node-SA532X7XB01216": [486.17, 379.91] },
  { "s-node-SA532X5XB00478": [403.17, 379.91] },
  { "s-node-SA532X4XB00273": [319.51, 379.91] },
  { "s-node-SA532X3XB00210": [236.34, 380.08] },
  { "s-node-SA532X2XB00147": [151.5, 378.91] },
  { "s-node-SA532X1XB00118": [68.96, 378.36] }
];
const SA609X3X8MB03073 = [
  { "s-node-SA609X3X8MB03073": [185.78, 371.45] },
  { "s-node-SA609X3X8XB03074": [372.0, 374.16] },
  { "s-node-SA609X3X8XB03176": [559.5, 376.57] },
  { "s-node-SA609X3X8XB03241": [747.01, 376.57] },
  { "s-node-SA609X3X8XB03300": [926.1, 376.5] }
];
const SA1035X4XB02879 = [
  { "s-node-SA1035X8XB03631": [595.09, 506.86] },
  { "s-node-SA1035X8XB03425": [597.09, 374.86] },
  { "s-node-SA1035X8XB03420": [595.09, 289.86] },
  { "s-node-SA1035X7XB03502": [483.41, 510.0] },
  { "s-node-SA1035X7XB03338": [485.62, 331.47] },
  { "s-node-SA1035X7XB03340": [488.16, 247.67] },
  { "s-node-SA1035X6XB03216": [377.62, 508.0] },
  { "s-node-SA1035X6XB03211": [379.76, 293.32] },
  { "s-node-SA1035X6XB03209": [386.04, 194.27] },
  { "s-node-SA1035X5XB03021": [270.54, 510.14] },
  { "s-node-SA1035X5XB03015": [273.22, 239.25] },
  { "s-node-SA1035X4XB02879": [167.79, 376.5] }
];
const CellLine = [
  { "s-node-SA906bX55": [673.76, 338.47] },
  { "s-node-SA039X60": [674.26, 189.97] },
  { "s-node-SA906bX50": [598.76, 338.47] },
  { "s-node-SA906aX57": [599.11, 189.82] },
  { "s-node-SA906bX45": [524.11, 339.82] },
  { "s-node-SA906aX50": [524.11, 189.82] },
  { "s-node-SA906bX40": [448.76, 339.47] },
  { "s-node-SA906aX40": [456.26, 188.97] },
  { "s-node-SA039X60": [373.92, 489.47] },
  { "s-node-SA906bX35": [374.11, 338.82] },
  { "s-node-SA906aX30": [381.26, 188.97] },
  { "s-node-SA039X51": [306.61, 489.32] },
  { "s-node-SA906bX30": [302.76, 339.82] },
  { "s-node-SA906aX25": [306.26, 188.97] },
  { "s-node-SA039X30": [229.11, 488.82] },
  { "s-node-SA906bX20": [227.61, 339.97] },
  { "s-node-SA906aX15": [231.26, 188.97] },
  { "s-node-SA039X25": [156.61, 489.32] },
  { "s-node-SA906aX10": [156.26, 263.97] }
];
const SA535X5XB02895 = [
  { "s-node-SA535X10XB03693": [696.53, 420.45] },
  { "s-node-SA535X10XB03696": [696.06, 333.33] },
  { "s-node-SA535X9XB03616": [593.72, 461.88] },
  { "s-node-SA535X9XB03617": [593.48, 377.9] },
  { "s-node-SA535X9XB03776": [593.48, 241.6] },
  { "s-node-SA535X8XB03434": [486.52, 503.03] },
  { "s-node-SA535X8XB03431": [485.44, 419.59] },
  { "s-node-SA535X8XB03664": [481.52, 241.31] },
  { "s-node-SA535X7XB03305": [380.85, 552.79] },
  { "s-node-SA535X7XB03304": [380.3, 457.12] },
  { "s-node-SA535X7XB03448": [379.5, 241.54] },
  { "s-node-SA535X6XB03101": [275.56, 512.27] },
  { "s-node-SA535X6XB03099": [268.66, 242.44] },
  { "s-node-SA535X5XB02895": [167.75, 372.14] }
];
const SA609X1XB00290 = [
  { "s-node-SA609X4XB01722": [194.55, 132.46] },
  { "s-node-SA609X10XB02454": [502.58, 102.17] },
  { "s-node-SA609X9XB02360": [439.58, 100.17] },
  { "s-node-SA609X8XB02312": [380.57, 100.17] },
  { "s-node-SA609X8XB02311": [380.57, 32.06] },
  { "s-node-SA609X7XB03556": [342.57, 665.97] },
  { "s-node-SA609X7XB03554": [343.57, 597] },
  { "s-node-SA609X7XB03508": [342.57, 469.03] },
  { "s-node-SA609X7XB03510": [342.57, 431.45] },
  { "s-node-SA609X7XB03506": [342.57, 368.34] },
  { "s-node-SA609X7XB03505": [342.57, 326.24] },
  { "s-node-SA609X7XB03574": [336.57, 240.03] },
  { "s-node-SA609X7XB03578": [338.57, 200.93] },
  { "s-node-SA609X7XB03573": [336.57, 131] },
  { "s-node-SA609X7XB02184": [338.57, 66] },
  { "s-node-SA609X6XB03447": [289.57, 629.42] },
  { "s-node-SA609X6XB03400": [293.57, 511.21] },
  { "s-node-SA609X6XB03401": [293.57, 541.1] },
  { "s-node-SA609X6XB03402": [292.68, 570.0] },
  { "s-node-SA609X6XB03404": [287.57, 397.2] },
  { "s-node-SA609X6XB03387": [287.57, 201] },
  { "s-node-SA609X6XB01899": [287.57, 66.61] },
  { "s-node-SA609X5XB03235": [241.56, 700] },
  { "s-node-SA609X5XB03223": [238.56, 629.42] },
  { "s-node-SA609X5XB03231": [238.56, 284.66] },
  { "s-node-SA609X5XB03230": [238.56, 467] },
  { "s-node-SA609X5XB03226": [236.56, 201] },
  { "s-node-SA609X5XB01844": [236.56, 66.61] },
  { "s-node-SA609X4XB03084": [190.56, 701.07] },
  { "s-node-SA609X4XB03080": [192.56, 629.42] },
  { "s-node-SA609X4XB03083": [192.56, 353] },
  { "s-node-SA609X4XB01721": [192.56, 66.61] },
  { "s-node-SA609X3XB01584": [150.56, 371.07] },
  { "s-node-SA609X2XB00426": [103.55, 370.07] },
  { "s-node-SA609X1XB00290": [53.03, 368.55] }
];
const SA609X3X8XB03076 = [
  { "s-node-SA609X3X8XB03375": [635.86, 377.21] },
  { "s-node-SA609X3X8XB03302": [506.86, 375.21] },
  { "s-node-SA609X3X8XB03242": [380.86, 375.21] },
  { "s-node-SA609X3X8XB03179": [254.86, 374.54] },
  { "s-node-SA609X3X8XB03076": [130.6, 374] }
];

const voronoiData = {
  SA609X3X8MB03073: SA609X3X8MB03073,
  SA532X1XB00118: SA532X1XB00118,
  SA039Mx2SA906b0: SA039Mx2SA906b0,
  SA535X5XB02895: SA535X5XB02895,
  SA609X1XB00290: SA609X1XB00290,
  SA609X3X8XB03076: SA609X3X8XB03076,
  SA1035X4XB02879: SA1035X4XB02879,
  CellLine: CellLine
};
export default voronoiData;
