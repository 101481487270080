const jsonData = {
  took: 4,
  timed_out: false,
  _shards: { total: 3, successful: 3, failed: 0 },
  hits: {
    total: 53,
    max_score: 1.0,
    hits: [
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9H4sOWpH762tL3g",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-899",
          submission_date: "2017-09-29",
          description:
            "SA609X6XB01898 (X0028-121D12) xenograft cells. PBAL and 10X in parallel.",
          xenograft_id: "X0028-121D12",
          pool_id: "A95632B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 637,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X6XB01898",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9LXsOWpH762tL3i",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-905",
          submission_date: "2018-02-14",
          description: "X0028-121D132 digested cells",
          xenograft_id: "X0028-121D132",
          pool_id: "A95728A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 876,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X7XB02184",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9MxsOWpH762tL3j",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-877",
          submission_date: "2017-08-18",
          description: "TOV2295 ~600 cells",
          xenograft_id: "None",
          pool_id: "A90554A",
          sample_type: "Cell Line",
          cell_line_id: "TOV2295(R)",
          anonymous_patient_id: "DAH355",
          num_sublibraries: 654,
          dashboard: "QC Dashboard",
          additional_pathology_info: "HGSOC",
          sample_id: "SA921",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Ovarian cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9OMsOWpH762tL3k",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-878",
          submission_date: "2017-08-21",
          description: "OV2295(R2) ~600 cells",
          xenograft_id: "None",
          pool_id: "A90554B",
          sample_type: "Cell Line",
          cell_line_id: "OV2295(R2)",
          anonymous_patient_id: "None",
          num_sublibraries: 618,
          dashboard: "QC Dashboard",
          additional_pathology_info: "HGSOC",
          sample_id: "SA922",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Ovarian cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9PnsOWpH762tL3l",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-861",
          submission_date: "2017-07-25",
          description:
            "GM18507 protease range, tag range, lysis 2 or 19h presoak",
          xenograft_id: "None",
          pool_id: "A90553A",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 612,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9SdsOWpH762tL3n",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-862",
          submission_date: "2017-05-31",
          description:
            "GM18507 cells for lysis tests 1/10nL G2 or Viagen, with and without protease top-up. Support experiment for paper.",
          xenograft_id: "None",
          pool_id: "A90648B",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 620,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8sPsOWpH762tL3N",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-884",
          submission_date: "2017-11-01",
          description: "SSM2 22D4 mouse tongue sarcoma",
          xenograft_id: "",
          pool_id: "A95624A",
          sample_type: "Other",
          cell_line_id: "",
          anonymous_patient_id: "SSM2 22D4",
          num_sublibraries: 634,
          dashboard: "QC Dashboard",
          additional_pathology_info: "",
          sample_id: "SA1083",
          taxonomy_id: "Mus Musculus (house mice)",
          quality: "0.75",
          pathology_disease_name: "Synovial sarcoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8z2sOWpH762tL3S",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-826",
          submission_date: "2018-03-01",
          description:
            "X0662-12214143141 Cells vs Nuclei and funnel test - check for presence of indices from A95728A",
          xenograft_id: "X0662-12214143141",
          pool_id: "A96187A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 2027,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA501X11XB00529",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9WxsOWpH762tL3q",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-863",
          submission_date: "2017-08-14",
          description:
            "GM cells and nuclei storage time test (overnight vs 2 months frozen)",
          xenograft_id: "None",
          pool_id: "A90689A",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 378,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9bEsOWpH762tL3t",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-955",
          submission_date: "2017-01-13",
          description:
            "Tn5 range with humidifier, viagen lysis with and without protease, 1.5x protocol\r\n\r\nSC-415",
          xenograft_id: "None",
          pool_id: "A90706",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 522,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp82ssOWpH762tL3U",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-889",
          submission_date: "2017-11-23",
          description: "X0662-11312",
          xenograft_id: "X0662-11312",
          pool_id: "A95670A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 645,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA501X5XB00877",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp88jsOWpH762tL3Y",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-904",
          submission_date: "2017-12-06",
          description: "Late passage SA532",
          xenograft_id: "X0002-35337317",
          pool_id: "A96145A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 642,
          dashboard: "QC Dashboard",
          additional_pathology_info: "HER2+",
          sample_id: "SA532X8XB01398",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah47wUn-BqF3SDHfRQ",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1096",
          submission_date: "2017-09-05",
          description: "p53 -/- passage 27, mammary epithelial cell line",
          xenograft_id: "",
          pool_id: "A73046B_A73056B_A90554C_A90600C_A95660A",
          sample_type: "Cell Line",
          cell_line_id: "184-hTERT L9 95.22",
          anonymous_patient_id: "",
          num_sublibraries: 8709,
          sample_id: "SA906",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah5etYn-BqF3SDHfRV",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1097",
          submission_date: "2018-01-16",
          description: "Cell cycle sorted: breast ductal carcinoma cell line",
          xenograft_id: "",
          pool_id: "A96147A_A96139A",
          sample_type: "Cell Line",
          cell_line_id: "T-47D",
          anonymous_patient_id: "",
          num_sublibraries: 3586,
          sample_id: "SA1044",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer",
          additional_pathology_info: "ER+",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah4ybcn-BqF3SDHfRO",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1095",
          submission_date: "2017-07-25",
          description: "GM18507 cell cycle sort",
          xenograft_id: "",
          pool_id: "A73044A_A90553C",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "",
          num_sublibraries: 3050,
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9jyc0zd1wXc0Eizb",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1802",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96193B",
          num_sublibraries: 2410,
          description: "184-hTERT 99.5 p35a",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT L9 99.5",
          sample_id: "SA1101",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-01-31"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9j3V0zd1wXc0Eizh",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2162",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96199A",
          num_sublibraries: 843,
          description: "FNA sample",
          anonymous_patient_id: "PBC0004126",
          cell_line_id: "None",
          sample_id: "SA1135",
          sample_type: "Patient",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "Breast cancer",
          additional_pathology_info: "TNBC",
          submission_date: "2019-05-04"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9j9H0zd1wXc0Eizp",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2618",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96199B",
          num_sublibraries: 1170,
          description: "hTERT wt p30 directly from cryo",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT-L9",
          sample_id: "SA039",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-08-06"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9jm_0zd1wXc0EizV",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2611",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96172B",
          num_sublibraries: 1694,
          description: "184-hTERT 95.22 p35b",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT L9 95.22",
          sample_id: "SA906",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-08-06"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8nOsOWpH762tL3K",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-874",
          submission_date: "2017-09-05",
          description: "184-hTERT wt p25",
          xenograft_id: "None",
          pool_id: "A73044B",
          sample_type: "Cell Line",
          cell_line_id: "184-hTERT-L9",
          anonymous_patient_id: "None",
          num_sublibraries: 2085,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA039",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8pbsOWpH762tL3L",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-875",
          submission_date: "2017-09-05",
          description: "184-hTERT wt p51",
          xenograft_id: "None",
          pool_id: "A73047D",
          sample_type: "Cell Line",
          cell_line_id: "184-hTERT-L9",
          anonymous_patient_id: "None",
          num_sublibraries: 2091,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA039",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8trsOWpH762tL3O",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-885",
          submission_date: "2017-11-10",
          description:
            "SSM2 20D1\r\nmouse tongue sarcoma\r\nDouble protease test",
          xenograft_id: "",
          pool_id: "A90560B",
          sample_type: "Other",
          cell_line_id: "",
          anonymous_patient_id: "SSM2 20D1",
          num_sublibraries: 795,
          dashboard: "QC Dashboard",
          additional_pathology_info: "",
          sample_id: "SA1085",
          taxonomy_id: "Mus Musculus (house mice)",
          quality: "0.75",
          pathology_disease_name: "Synovial sarcoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8vHsOWpH762tL3P",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-881",
          submission_date: "2018-01-31",
          description: "HeLA library",
          xenograft_id: "",
          pool_id: "A96150A",
          sample_type: "Cell Line",
          cell_line_id: "HeLa",
          anonymous_patient_id: "",
          num_sublibraries: 687,
          dashboard: "QC Dashboard",
          additional_pathology_info: "",
          sample_id: "SA1087",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Cervical epithelial adenocarcinoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8wksOWpH762tL3Q",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-886",
          submission_date: "2018-02-08",
          description:
            "Steidl Collab Pilot sample 12-10882 2012 (T1). 10X done in parallel by Liz Chavez.",
          xenograft_id: "None",
          pool_id: "A96156A",
          sample_type: "Patient",
          cell_line_id: "None",
          anonymous_patient_id: "12-10882 2012",
          num_sublibraries: 678,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA1088",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Follicular lymphoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp81QsOWpH762tL3T",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-888",
          submission_date: "2017-10-06",
          description:
            "X0662-11, immediate precursor to SA501X3F and SA501X4F in DLP paper",
          xenograft_id: "X0662-11",
          pool_id: "A95621B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 633,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA501X2XB00096",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp84KsOWpH762tL3V",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-891",
          submission_date: "2017-11-24",
          description: "X0662-113123",
          xenograft_id: "X0662-113123",
          pool_id: "A95670B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 657,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA501X6XB00969",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp87FsOWpH762tL3X",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-903",
          submission_date: "2018-02-08",
          description: "X0002-3533",
          xenograft_id: "X0002-3533",
          pool_id: "A95732B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "CBX002",
          num_sublibraries: 655,
          dashboard: "QC Dashboard",
          additional_pathology_info: "HER2+",
          sample_id: "SA532X4XB00273",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp899sOWpH762tL3Z",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-893",
          submission_date: "2017-12-06",
          description: "Early passage SA535",
          xenograft_id: "X0011-2",
          pool_id: "A96165B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 399,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA535X1XB00174",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8_YsOWpH762tL3a",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-787",
          submission_date: "2018-02-08",
          description: "X0011-23121",
          xenograft_id: "X0011-23121",
          pool_id: "A95732A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "CBX011",
          num_sublibraries: 960,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA535X5XB00517",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9AzsOWpH762tL3b",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-856",
          submission_date: "2017-12-14",
          description:
            "X0011-23121523 late passage SA535 and 2 month fridge GM storage test",
          xenograft_id: "X0011-23121523",
          pool_id: "A95736A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 1154,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA535X8XB0143",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9FCsOWpH762tL3e",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-896",
          submission_date: "2017-10-23",
          description:
            "X0028-121D Un-Rx Control-Very fast growing digested cells",
          xenograft_id: "X0028-121D",
          pool_id: "A95664B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 630,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X4XB01721",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9RAsOWpH762tL3m",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-873",
          submission_date: "2017-11-09",
          description:
            "1 week fridge storage and Hoechst-PI, Draq5 only, Draq5-PI",
          xenograft_id: "None",
          pool_id: "A90560A",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 600,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9T1sOWpH762tL3o",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-870",
          submission_date: "2017-04-04",
          description: "primer wash test and mixing cells expt",
          xenograft_id: "None",
          pool_id: "A90682",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 624,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9YNsOWpH762tL3r",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-864",
          submission_date: "2017-08-14",
          description: "2.2nl tag - C1 cells to depth library",
          xenograft_id: "None",
          pool_id: "A90689B",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 735,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9eMsOWpH762tL3v",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-901",
          submission_date: "2017-10-24",
          description: "overnight vs. 2 hour C/H/dispase digest",
          xenograft_id: "X1887-111G2",
          pool_id: "A95664A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 489,
          dashboard: "QC Dashboard",
          additional_pathology_info: "ER+BRCA2 mut",
          sample_id: "SA995X5XB01910",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah45IKn-BqF3SDHfRP",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1094",
          submission_date: "2017-04-27",
          description: "male diploid human lymphoblastoid cell line",
          xenograft_id: "",
          pool_id: "A90694A_A90694B",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "",
          num_sublibraries: 1715,
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah4mLfn-BqF3SDHfRM",
        _score: 1.0,
        _source: {
          pool_id: "A75616ABC_A75617A",
          jira_ticket: "SC-1093",
          num_sublibraries: 941,
          description: "Optimization: Tn5 volume and PCR cycles",
          anonymous_patient_id: "",
          cell_line_id: "GM18507",
          sample_id: "SA928",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          quality: "0.75",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9jdA0zd1wXc0EizP",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2608",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96146A",
          num_sublibraries: 1195,
          description: "hTERT 95.22 p57a directly from cryo",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT L9 95.22",
          sample_id: "SA906",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-08-06"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9kPY0zd1wXc0Eiz7",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2620",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96226B",
          num_sublibraries: 1274,
          description: "FNA sample and hTERT wt p60 directly from cryo",
          anonymous_patient_id: "PBC0004340",
          cell_line_id: "None",
          sample_id: "SA039",
          sample_type: "Patient",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "Breast cancer",
          additional_pathology_info: "ER+ PR+ HER2-",
          submission_date: "2019-08-06"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8q1sOWpH762tL3M",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-883",
          submission_date: "2017-10-31",
          description: "SSM2 22D1 mouse tongue sarcoma",
          xenograft_id: "",
          pool_id: "A95622A",
          sample_type: "Other",
          cell_line_id: "",
          anonymous_patient_id: "SSM2 22D1",
          num_sublibraries: 539,
          dashboard: "QC Dashboard",
          additional_pathology_info: "",
          sample_id: "SA1075",
          taxonomy_id: "Mus Musculus (house mice)",
          quality: "0.75",
          pathology_disease_name: "Synovial sarcoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp8yAsOWpH762tL3R",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-887",
          submission_date: "2018-02-08",
          description:
            "Steidl Collab Pilot sample 12-10882 2016 (T2). 10X done in parallel by Liz Chavez.",
          xenograft_id: "None",
          pool_id: "A96156B",
          sample_type: "Patient",
          cell_line_id: "None",
          anonymous_patient_id: "12-10882 2016",
          num_sublibraries: 397,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA1089",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Follicular lymphoma"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp85osOWpH762tL3W",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-902",
          submission_date: "2017-12-06",
          description:
            "Early passage SA532 + 2x freeze thaw cells (pooled from another chip into this library)",
          xenograft_id: "X0002-35",
          pool_id: "A96165A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 779,
          dashboard: "QC Dashboard",
          additional_pathology_info: "HER2+",
          sample_id: "SA532X2XB00147",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9CLsOWpH762tL3c",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-894",
          submission_date: "2017-11-21",
          description:
            "X0038-124619 Un-Rx Control-Slow growing. overnight vs. 2h C/H digest and DNase test",
          xenograft_id: "X0038-124619",
          pool_id: "A95722A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 992,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA604X6XB01979",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9DmsOWpH762tL3d",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-895",
          submission_date: "2017-11-30",
          description: "X0028-121 digested cells",
          xenograft_id: "X0028-121",
          pool_id: "A95724A",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 503,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X3XB01584",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9GdsOWpH762tL3f",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-898",
          submission_date: "2017-12-01",
          description: "X0028-121D1 digested cells",
          xenograft_id: "X0028-121D1",
          pool_id: "A95724B",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 581,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X5XB01844",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9JbsOWpH762tL3h",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-900",
          submission_date: "2017-10-06",
          description: "SA609X6XB01899 C/H digest cells",
          xenograft_id: "X0028-121D13",
          pool_id: "A95632D",
          sample_type: "Xenograft",
          cell_line_id: "None",
          anonymous_patient_id: "None",
          num_sublibraries: 644,
          dashboard: "QC Dashboard",
          additional_pathology_info: "TNBC",
          sample_id: "SA609X6XB01899",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9VUsOWpH762tL3p",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-857",
          submission_date: "2016-12-30",
          description:
            "CellenONE vs Poisson with tag test, scatter spotting vs block of wells\r\nSC-410",
          xenograft_id: "None",
          pool_id: "A90685",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 481,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9ZqsOWpH762tL3s",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-865",
          submission_date: "2017-08-14",
          description: "3.5nl tag - C1 cells to depth library",
          xenograft_id: "None",
          pool_id: "A90689C",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 718,
          dashboard: "QC Dashboard",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWah5rVPn-BqF3SDHfRX",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1098",
          submission_date: "2018-02-23",
          description:
            "female human breast cancer met(skin/chest) ER+ PR- HER- PDX, passage 3, nuclei",
          xenograft_id: "X0766-174",
          pool_id: "A96171A_A96176A",
          sample_type: "Xenograft",
          cell_line_id: "",
          anonymous_patient_id: "VBA0766/POG004",
          num_sublibraries: 799,
          sample_id: "SA611X3XB00821",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "Breast cancer",
          additional_pathology_info: "ER+ PR- Her2-",
          dashboard: "Combined QC Dashboard"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AWaOp9cysOWpH762tL3u",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-1043",
          submission_date: "2016-06-30",
          description: "Microfluidic library",
          xenograft_id: "None",
          pool_id: "MF1606301",
          sample_type: "Cell Line",
          cell_line_id: "GM18507",
          anonymous_patient_id: "None",
          num_sublibraries: 192,
          dashboard: "QC Dashboard Combined",
          additional_pathology_info: "None",
          sample_id: "SA928",
          taxonomy_id: "Homo sapiens (human)",
          quality: "0.75",
          pathology_disease_name: "None"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9kCL0zd1wXc0Eizv",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2610",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96211C",
          num_sublibraries: 1397,
          description: "184-hTERT 95.22 p30b",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT L9 95.22",
          sample_id: "SA906",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-08-06"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "AW2X9kKa0zd1wXc0Eiz1",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2748",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96225C",
          num_sublibraries: 1034,
          description: "184-hTERT 99.5 p30a from cryo",
          anonymous_patient_id: "None",
          cell_line_id: "184-hTERT L9 99.5",
          sample_id: "SA1101",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "None",
          additional_pathology_info: "None",
          submission_date: "2019-08-26"
        }
      },
      {
        _index: "meta_data_stats",
        _type: "meta_data_stats",
        _id: "SC-2640",
        _score: 1.0,
        _source: {
          jira_ticket: "SC-2640",
          dashboard: "QC Dashboard",
          quality: "0.75",
          pool_id: "A96213A",
          num_sublibraries: 800,
          description: "OV2295",
          anonymous_patient_id: "None",
          cell_line_id: "OV2295",
          sample_id: "SA1090",
          sample_type: "Cell Line",
          taxonomy_id: "Homo sapiens (human)",
          xenograft_id: "None",
          pathology_disease_name: "Ovarian cancer",
          additional_pathology_info: "HGSOC",
          submission_date: "2018-02-14"
        }
      }
    ]
  }
};
export default jsonData;
